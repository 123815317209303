import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

// ---------------------
// Theme Modules
// ---------------------
import './theme/css/lineicons.css'
// import './theme/css/fullcalendar.css'
// import './theme/css/morris.css'
// import './theme/css/datatable.css'
// import './theme/css/main.css'

// import DataTable from './theme/js/datatable.js';
// import './theme/js/Sortable.min.js';

import './theme/scss/main.scss';
import './theme/js/main.js';

import './js/image-uploader.js';
import './js/quill.js';

import {DataTable} from 'simple-datatables';
import 'simple-datatables/dist/style.css';

const findAllDatatables = document.querySelectorAll('#table');
if (findAllDatatables.length > 0) {
    findAllDatatables.forEach(single => {
        const dataTable = new DataTable(single, {
            searchable: true,
        });
    });
}

const youtubeVideo = document.querySelector('#activity_activityDetailsYoutubeVideo');
if (youtubeVideo) {
    // when youtube video input changes, update iframe src
    console.log(youtubeVideo);
    youtubeVideo.oninput = (e) => {
        const target = e.target;
        if (target) {
            // get parent node
            const getParent = target.parentNode;
            if (getParent) {
                // get iframe
                const getIframe = getParent.querySelector('iframe');

                // remove d-none class
                if (target.value.length > 5) {
                    getIframe.classList.remove('d-none');
                }
                else {
                    getIframe.classList.add('d-none');
                }

                if (getIframe) {
                    // update iframe src
                    getIframe.setAttribute('src', target.value);
                }
            }
        }
    };
}