import axios from 'axios';

const getImageUploader = document.querySelectorAll('.image-uploader');
if (getImageUploader.length > 0) {
    getImageUploader.forEach(imageUploader => {
        const form = imageUploader.closest('form');
        // check form exists
        if (form) {
            // update upload path
            const getFormParent = form.closest('[data-upload-path]');
            if (getFormParent) {
                const getInputUploadPath = form.querySelector('.image-uploader-path');
                getInputUploadPath.value = getFormParent.dataset.uploadPath;
            }
            form.addEventListener('submit', (e) => {
                e.preventDefault();
                const getInputUploadPath = form.querySelector('.image-uploader-path');
                const formData = new FormData(form);
                console.log(getInputUploadPath);

                if (getInputUploadPath.value) {
                    // send form data to server
                    axios.post(form.action, formData)
                    .then((response) => {
                        const data = response.data;
                        if (data) {
                            if (data.message == 'success' && data.content) {
                                appendUploadedFiles(getFormParent, data.content);
                            }
                        }
                        console.log(response);
                    })
                }
            });
        }
    });
}


function appendUploadedFiles(appendAfterTarget, content) {
    if (appendAfterTarget && content) {
        // remove existing uploaded files
        let getUploadedImageContainer = document.querySelector('.uploaded-images');
        getUploadedImageContainer.remove();

        // convert content to html elements
        const parser = new DOMParser();
        var doc = parser.parseFromString(content, 'text/html');

        // reassive the uploaded images container
        getUploadedImageContainer = doc.querySelector('.uploaded-images');

        // append uploaded images container
        appendAfterTarget.insertAdjacentElement('afterend', getUploadedImageContainer);
    }
}