import 'quill/dist/quill.snow.css';
import Quill from 'quill';

const findAllQuill = document.querySelectorAll('.quill-initiator');
// const findAllQuill = document.querySelector('.quill');
if (findAllQuill.length > 0) {
    findAllQuill.forEach(single => {
        const getContent = single.value;
        const quillElement = document.createElement('div');
        quillElement.classList.add('quill');
        quillElement.innerHTML = getContent;
        quillElement.style.minHeight = '300px';
        quillElement.style.backgroundColor = '#fff';
        single.parentNode.append(quillElement);

        // hide textarea, as quill works from a div
        single.style.display = 'none';
        
        const toolbar = [
            ['bold', 'italic', 'underline'],
            ['link', 'blockquote', 'code', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'align': [] }],
            ['clean'],
        ];

        // init quill
        if (quillElement) {
            const quill = new Quill(quillElement, {
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: toolbar,
                        handlers: {
                            image: imageHandler,
                        }
                    }
                }
            });
            // updated hidden textarea on change
            quill.on('editor-change', (eventname) => {
                console.log(single.value);
                single.value = quill.root.innerHTML;
            });
        }
    });

    function imageHandler() {
        var range = this.quill.getSelection();
        var value = prompt('please copy paste the image url here.');
        if(value){
            this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
        }
    }
}